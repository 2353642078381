(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("vue"), require("fsxa-api"));
	else if(typeof define === 'function' && define.amd)
		define([, "fsxa-api"], factory);
	else if(typeof exports === 'object')
		exports["fsxa-pattern-library"] = factory(require("vue"), require("fsxa-api"));
	else
		root["fsxa-pattern-library"] = factory(root["Vue"], root["fsxa-api"]);
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__8bbf__, __WEBPACK_EXTERNAL_MODULE_d9f6__) {
return 